import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from '../common/Image';
import Rating from '../product/Rating';
// import Glide from '@glidejs/glide';
import Glide, { Autoplay } from '@glidejs/glide/dist/glide.modular.esm';

const reviews = [
    {
        name: 'Anonymous',
        reviewContent:
            'Excellent service with polite updates on the service provided ',
    },
    {
        name: 'Anonymous',
        reviewContent:
            'This hamper was amazing! Loved the duo of quality wines provided along with the treats. It was the perfect gift with fast delivery! We loved that we could add extra items to the hamper too for that personal touch...',
    },
    {
        name: 'Anonymous',
        reviewContent:
            'I found gourmetbasket.com.au very easy to use when ordering a gift for a family member.  The website provides so many great gift options.  The site is easy to navigate and well presented. Loved that we found this...',
    },
    {
        name: 'Sue Leak',
        reviewContent:
            'easy to order, prompt delivery and product as advertised - Excellent all around!',
    },
    {
        name: 'Anonymous',
        reviewContent:
            'Arrived on due date, great presentation, quality products',
    },
    {
        name: ' Jan &amp; Bill ...',
        reviewContent:
            'Our friends were delighted with your basket and it was delivered exactly the date we specified.',
    },
    {
        name: 'Anonymous',
        reviewContent:
            'arrived on time and we were very happy with the presentation and quality of gift ',
    },
    {
        name: ' Kirsten Bowler',
        reviewContent: 'Really great will continue to use your company 👍😊',
    },
    {
        name: ' Leonie Klaric',
        reviewContent: 'fantastic baskets and great service',
    },

    {
        name: 'Anonymous',
        reviewContent:
            'Very good service. I had a bit of difficulty ordering from NZ but one quick phone call to the company and everything was sorted. Daughter loved her hamper. Thank you. ',
    },
    {
        name: ' Sandra McCabe',
        reviewContent: 'Great customer service and delivery.',
    },
    {
        name: ' Jessica Voglis',
        reviewContent:
            'Highly recommend! My friend loved her pamper hamper. Perfect packaging and top quality products. Thank you!',
    },
    {
        name: ' Susan Huston ',
        reviewContent:
            'Baskets  were very good,  I was charged for two baskets and two got delivered but unsure how that happened, only one was ordered.',
    },
    {
        name: 'Anonymous',
        reviewContent: 'Very good company, used many times.',
    },
    {
        name: ' alison luce',
        reviewContent:
            'I was very pleased with how easy it was to order gifts from the online Gourmet basket website, and the choice was so good. My son was pleased with the gift which was beautifully packaged and arrived on time. I wo...',
    },
    {
        name: 'Anonymous',
        reviewContent: 'Excellent presentation.',
    },
    {
        name: ' Veronica Walker',
        reviewContent: 'My sister loved it',
    },
    {
        name: ' Diane Immerz ',
        reviewContent: ' Happy with service. Will use again when necessary.',
    },
    {
        name: ' Anonymous',
        reviewContent: 'Excellent service ',
    },
    {
        name: ' Wynette Horne',
        reviewContent:
            'My friends, celebrating their 40th Wedding Anniversary were blown away with their gift.The wrapping, quality of contents and quick delivery were all excellent. Thank you for creating such a beautiful gift.',
    },
];

type Props = {};

const Qoute = () => {
    return (
        <svg
            width="38"
            height="24"
            viewBox="0 0 38 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.52892 23.1955C8.02213 23.1955 6.7306 22.9134 5.5467 22.4432C4.36279 21.973 3.39414 21.3147 2.74838 20.3743C2.10261 19.716 1.56447 18.7755 1.24159 17.4589C0.81108 16.2364 0.595825 14.8258 0.595825 13.227C0.595825 10.6879 1.34922 8.33683 2.85601 6.07981C4.36279 3.91683 6.62297 2.13002 9.74417 0.907471L10.4976 2.22407C8.99078 2.88236 7.69925 3.72874 6.51534 4.85725C5.33144 5.98576 4.47042 7.20832 4.03991 8.43087C3.50177 9.84151 3.39414 11.2521 3.82465 12.5687C5.33144 10.97 7.37637 10.2177 10.1747 10.2177C12.4349 10.2177 14.2645 10.7819 15.6637 11.9104C17.0629 13.0389 17.7086 14.6377 17.7086 16.7066C17.7086 18.6815 16.9552 20.2802 15.4484 21.4087C13.9417 22.6313 12.0044 23.1955 9.52892 23.1955ZM29.44 23.1955C27.9332 23.1955 26.6417 22.9134 25.4578 22.4432C24.2739 21.973 23.3053 21.3147 22.6595 20.3743C21.9061 19.716 21.4756 18.7755 21.0451 17.4589C20.6146 16.2364 20.3993 14.8258 20.3993 13.227C20.3993 10.6879 21.1527 8.33683 22.6595 6.07981C24.1663 3.91683 26.4265 2.13002 29.5477 0.907471L30.3011 2.22407C28.9019 2.88236 27.5027 3.72874 26.3188 4.85725C25.1349 5.98576 24.2739 7.20832 23.8434 8.43087C23.3053 9.84151 23.1976 11.2521 23.6281 12.5687C25.1349 10.97 27.1799 10.2177 29.8705 10.2177C32.1307 10.2177 33.9604 10.7819 35.3596 11.9104C36.7587 13.0389 37.4045 14.6377 37.4045 16.7066C37.4045 18.6815 36.6511 20.2802 35.1443 21.4087C33.7451 22.6313 31.8078 23.1955 29.44 23.1955Z"
                fill="#B08C56"
            />
        </svg>
    );
};
const Review = (props: Props) => {
    const UNIQUE_CLASS = `ReviewSlider_glide_uClass`;

    useEffect(() => {
        if (document.querySelector(`.${UNIQUE_CLASS}`)) {
            setTimeout(() => {
                new Glide(`.${UNIQUE_CLASS}`, {
                    perView: 1,
                    autoplay: 3000,
                }).mount({ Autoplay });
            }, 10);
        }
    }, [UNIQUE_CLASS]);

    return (
        <div className="bg-[#A7A7A733] container my-8 py-7 flex justify-center">
            <div className="max-w-lg min-w-0">
                <div className="flex justify-center">
                    <Qoute />
                </div>
                <div className={`${UNIQUE_CLASS} `}>
                    <div className="glide__track " data-glide-el="track">
                        <ul className="glide__slides ">
                            {reviews.map((review, i) => {
                                return (
                                    <li
                                        key={i}
                                        className="glide__slide flex flex-col items-center text-center cursor-pointer"
                                    >
                                        <p className="block font-playfairdisplay text-lg md:text-2xl text-brand-primary-dark italic my-5 break-words">
                                            {review.reviewContent}
                                        </p>
                                        <p className="block font-playfairdisplay text-base  text-brand-primary-dark ">
                                            {review.name}
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center mt-5 gap-3">
                    <Link
                        href={`https://www.reviews.io/company-reviews/store/gourmetbasket-com-au`}
                        passHref
                        target="_blank"
                    >
                        <div className="relative w-[110px] h-[15px]">
                            <Image
                                width="110"
                                height="15"
                                alt="Reviews.io"
                                src={`https://assets.reviews.io/img/all-global-assets/platform-logos/logo-reviewsio--black.svg`}
                            />
                        </div>
                    </Link>
                </div>
                <div className="flex flex-col items-center justify-center my-7">
                    <Rating rating={4.7} />
                    <p className="text-black text-xs font-playfairdisplay font-bold my-2">
                        <span className="mr-2 font-playfairdisplay">
                            4.7 Rating
                        </span>{' '}
                        <span className="font-playfairdisplay">
                            7064 Reviews
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Review;
