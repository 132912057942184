import { GbEmptyStar, GbHalfStar, GbStar } from '../icons'
import { round } from 'lodash'

export default function Rating({ rating }) {
	let steps = round(rating, 0)
	// If rating is empty then no reviews yet
	if (rating === null) {
		steps == 0;
	}
	if (parseInt(rating) <= 0) {
		return null;
	}

	const remainingStars = 5 - steps

	return (

		<div className="flex items-center">
			<div className="flex items-center gap-1">
				{[...new Array(steps)].map((rate, index) => {
					const star = rating - index
					return (
						<div key={index}>
							{(star < 1) ? <GbHalfStar /> : <GbStar fillColor={rating > rate ? '#AF8C5A' : ''} />}
						</div>
					)
				})}

				{[...new Array(remainingStars)].map((rate, index) => {
					return (
						<div key={index}>
							<GbEmptyStar />
						</div>
					)
				})}
			</div>
		</div>

	)
}
